<main>
  @if(userService.isMsalAuthenticated()){
    @if(!userService.isFullyLoaded){
      <app-loading-spinner class="min-vh-100"></app-loading-spinner>
    }
    @if(!userService.isAPIAuthenticated() && userService.isFullyLoaded){
      <app-no-access></app-no-access>
    }
  }
  @else {
      <app-no-access></app-no-access>
  }
  @if(userService.isFullyLoaded && userService.isAPIAuthenticated()){ 
    <div class="d-flex flex-row pl-0 min-vh-100">
    @if(showSidebar()){
      <app-sidebar class="w-12p5 background-color-primary"></app-sidebar>
    }
    <div class="flex-fill content-area w-87p5">
      <app-navbar id="nav" [(expand)]="showSidebar" (expandChange)="showSidebar" (logoutUser)="logout()"></app-navbar>
      <div #main class="p-2">
        <router-outlet />
      </div>
    </div>
  </div> 
  }
</main>