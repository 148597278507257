import { Component, Inject, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { AuthService } from './shared/services/authorization/auth.service';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SidebarComponent } from './shared/components/sidenav/sidebar.component';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { UserService } from './shared/services/user/user.service';
import { IdleService } from './shared/services/idle/idle.service';
import { NoAccessComponent } from './shared/components/auth/no-access/no-access.component';
import config from 'devextreme/core/config'; 
import { licenseKey } from './devextreme-license'; 
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NavbarComponent, SidebarComponent, LoadingSpinnerComponent, NoAccessComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  authService = inject(AuthService);
  userService = inject(UserService);

  title = 'md-comp-web';
  loginDisplay = false;
  subscription!: Subscription;
  idleState = 'Not started.';
  timedOut = false;
  private readonly _destroying$ = new Subject<void>();

  showSidebar = signal<boolean>(false);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private _msalGuardConfig: MsalGuardConfiguration,
    private _msalService: MsalService,
    private _msalBroadcastService: MsalBroadcastService,
    private idle: Idle
  ) {

    this.idle.setIdle(900); // 15 minutes
    this.idle.setTimeout(60); // 1 minutes
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
      Swal.close();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();

      Swal.close();
      // Swal.fire({text: this.idleState, customClass: {popup: 'custom-swal-style'}, showConfirmButton: false});
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ll be logged out in 300 seconds!';

      Swal.fire({
        text: this.idleState,
        customClass: {
          popup: 'custom-swal-style'
        },
        showConfirmButton: false // Hide the OK button
      }).then((result) => {
        if (result.value) {
          this.stay();
        }
      });
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will be logged out due to inactivity in ' + countdown + ' seconds. Please save all work before logging out.';
      Swal.update({ text: this.idleState });
    });
  }

  ngOnInit(): void {
    config({ licenseKey }); 
    this._msalService.handleRedirectObservable().subscribe();

    this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this._msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this._msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });
      this.reset();
  }

  setLoginDisplay(): void {
    this.loginDisplay = this._msalService.instance.getAllAccounts().length > 0;
    this.userService.isMsalAuthenticated.update(() => this.loginDisplay);
    this.showSidebar.update(() => this.loginDisplay);
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */

    let activeAccount = this._msalService.instance.getActiveAccount();

    if (!activeAccount && this._msalService.instance.getAllAccounts().length > 0) {
      let accounts = this._msalService.instance.getAllAccounts();
      if(accounts[0].idToken){
        this.authService.setAccessToken(accounts[0].idToken);
        this.userService.getCurrentUser();
      }
    }
    else{
      if(activeAccount?.idToken){
        this.authService.setAccessToken(activeAccount.idToken);
        this.userService.getCurrentUser();
      } else {
        this.userService.isFullyLoaded = true;
      }
    }
  }

  loginRedirect(): void {
    if (this._msalGuardConfig.authRequest) {
      this._msalService.loginRedirect({ ...this._msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this._msalService.loginRedirect();
    }
  }

  logout(): void {
    this.loginDisplay = false;
    this.showSidebar.update(() => false);
    this.userService.isMsalAuthenticated.update(() => false);
    this.userService.isAPIAuthenticated.update(() => false);
    this.authService.removeAccessToken();
    this._msalService.logoutRedirect({
      postLogoutRedirectUri: `${environment.webUrl}/login`,
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subscription.unsubscribe();
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  stay() {
    this.reset();
  }
}
