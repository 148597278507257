import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-no-access',
  standalone: true,
  imports: [MatCardModule, MatButtonModule],
  template: `
    <div class="center wrapper">
      <mat-card class="login-card">
        <mat-card-header>
          <mat-card-title>
            <h4>You do not have access, please contact support.</h4>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <button mat-button color="warn" class="m-1">Contact Support</button>
        </mat-card-content>
      </mat-card>
    </div>
  `,
  styleUrl: '../auth.component.scss',
})
export class NoAccessComponent {}
