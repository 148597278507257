import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'employees',
    data: { breadcrumb: 'Home' },
    pathMatch: 'full',
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./components/dashboard/routes').then(m => m.DASHBOARD_ROUTES),
  //   data: { breadcrumb: 'Dashboard' },
  //   canActivate: [MsalGuard],
  // },
  // {
  //   path: 'progress-payments',
  //   loadComponent: () =>
  //     import('./components/progress-payments-view/progress-payments-view.component').then(m => m.ProgressPaymentsViewComponent),
  //   data: { breadcrumb: 'Progress Payments' },
  //   canActivate: [MsalGuard],
  // },
  {
    path: 'employees',
    loadChildren: () => import('./components/employees/routes').then(m => m.EMPLOYEE_ROUTES),
    data: { breadcrumb: 'Employees' },
    canActivate: [MsalGuard],
  },
  {
    path: 'tdc',
    loadComponent: () => import('./components/tdc-view/tdc-view.component').then(m => m.TdcViewComponent),
    data: { breadcrumb: 'TDC' },
    canActivate: [MsalGuard],
},

  {
    path: 'icp',
    loadComponent: () => import('./components/icp-view/icp-view.component').then(m => m.IcpViewComponent),
    data: { breadcrumb: 'ICP' },
    canActivate: [MsalGuard],
  },
  {
    path: 'emsp',
    loadComponent: () => import('./components/emsp-view/emsp-view.component').then(m => m.EmspViewComponent),
    data: { breadcrumb: 'EMSP' },
    canActivate: [MsalGuard],
  },
  {
    path: 'home',
    redirectTo: 'employees',
  },
  {
    path: 'audit-logs',
    loadComponent: () => import('./components/audit-logs/audit-logs.component').then(m => m.AuditLogsComponent),
    data: { breadcrumb: 'Audit Logs' },
    canActivate: [MsalGuard],
  },
  {
    path: 'profile',
    loadComponent: () => import('./components/profile/profile.component').then(m => m.ProfileComponent),
    data: { breadcrumb: 'Profile' },
    canActivate: [MsalGuard],
  },
  {
    path: 'admin-tables',
    loadComponent: () => import('./components/admin-tables/admin-tables.component').then(m => m.AdminTablesComponent),
    data: { breadcrumb: 'Admin Tables' },
    canActivate: [MsalGuard],
  },
  {
    path: 'budget-to-actual',
    loadComponent: () =>
      import('./components/budget-to-actual/budget-to-actual.component').then(m => m.BudgetToActualComponent),
    data: { breadcrumb: 'Budget to Actual' },
    canActivate: [MsalGuard]
  },
  // {
  //     path: 'approvals',
  //     loadComponent: () => import('./components/approvals/approvals.component').then(m => m.ApprovalsComponent),
  //     data: { breadcrumb: 'Approvals' },
  //     canActivate: [MsalGuard]
  // },
  {
    path: 'login-failed',
    loadComponent: () =>
      import('./shared/components/auth/login-failed/login-failed.component').then(m => m.LoginFailedComponent),
  },
  {
    path: 'login',
    loadComponent: () => import('./shared/components/auth/login/login.component').then(m => m.LoginComponent),
  },
];
