import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage/storage.service';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const authToken = inject(StorageService).getData()?.token;
  const newReq = req.clone({
    headers: req.headers
      .append('Authorization', `Bearer ${authToken}`)
      .append('Access-Control-Allow-Origin', '*'),
  });
  return next(newReq);
}
