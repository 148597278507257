import { Injectable } from '@angular/core';
import { Token } from 'app/core/models/auth/token.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private key: string = 'mdCompCookie';
  constructor(private cookieService: CookieService) {}

  setData(data: any): void {
    if (data.idToken && data.idTokenClaims) {
      const token: Token = {
        token: data.idToken,
        expiration: data.idTokenClaims.exp,
        expirationDate: new Date(data.idTokenClaims.exp * 1000),
        isExpired: false,
      };
      const tokenDetail = JSON.stringify(token);
      this.cookieService.set(this.key, tokenDetail, { secure: true });
    }
  }

  getData(): Token | null {
    const value = this.cookieService.get(this.key);
    if (value.length === 0) {
      return null;
    }
    const token = JSON.parse(value);
    if (token.expirationDate < Date.now()) {
      token.isExpired = true;
    }
    return token;
  }

  destroyData(): void {
    this.cookieService.delete(this.key);
    console.log('cookie deleted', this.cookieService.get(this.key));
  }
}
