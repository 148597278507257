<main>
  <div class="d-flex flex-row pl-0 min-vh-100">
    @if (showSidebar()) {
      <app-sidebar class="w-15 background-color-primary"></app-sidebar>
    }
    <div class="flex-fill content-area w-85">
      <app-navbar
        id="nav"
        [(expand)]="showSidebar"
        (expandChange)="(showSidebar)"
        (logoutUser)="logout()"
      ></app-navbar>
      <div #main class="p-2">
        @if (userService.isFullyLoaded) {
          <router-outlet />
        }
      </div>
    </div>
  </div>
</main>
