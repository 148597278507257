@if (userService.isMsalAuthenticated() && userService.isAPIAuthenticated()) {
  <aside class="h-100 background-color-primary position-fixed w-15">
    <div class="p-1">
      <div class="row pt-4 justify-content-center">
        <div class="col-12 text-center">
          <h6>
            <a class="text-decoration-none text-light" routerLink="/home">
              Global Consulting
              <br />
              MD / SD <br />Compensation Tool<br />
            </a>
          </h6>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="pt-4 d-flex flex-column justify-content-start">
        <mat-list role="nav">
          @if (currentPermission) {
            <!-- <mat-list-item role="navItem">
              <button mat-button routerLink="/dashboard" class="text-light" style="width: 100%">
                <mat-icon>account_balance</mat-icon> Dashboard
              </button>
            </mat-list-item> -->
            @if (currentPermission.progressPaymentTab.canView) {
              <mat-list-item role="navItem">
                <button
                  mat-button
                  routerLink="/progress-payments"
                  class="text-light"
                  style="width: 100%"
                >
                  <mat-icon>monitoring</mat-icon> Progress Payments
                </button>
              </mat-list-item>
            }
            @if (currentPermission.icpTab.canView) {
              <mat-list-item role="navItem">
                <button mat-button routerLink="/icp" class="text-light" style="width: 100%">
                  <mat-icon>attach_money</mat-icon> ICP
                </button>
              </mat-list-item>
            }

            @if (currentPermission.emspTab.canView) {
              <mat-list-item role="navItem">
                <button mat-button routerLink="/emsp" class="text-light" style="width: 100%">
                  <mat-icon>savings</mat-icon> US EMSP
                </button>
              </mat-list-item>
            }

            @if (currentPermission.tdcTab.canView) {
              <mat-list-item role="navItem">
                <button mat-button routerLink="/tdc" class="text-light" style="width: 100%">
                  <mat-icon>payments</mat-icon> TDC
                </button>
              </mat-list-item>
            }

            @if (currentPermission.employeesTab.canView) {
              <mat-list-item role="navItem">
                <button mat-button routerLink="/employees" class="text-light" style="width: 100%">
                  <mat-icon>person</mat-icon> Employees
                </button>
              </mat-list-item>
            }

            @if (currentPermission.adminTablesTab.canView) {
              <mat-list-item role="navItem">
                <button
                  mat-button
                  routerLink="/admin-tables"
                  class="text-light"
                  style="width: 100%"
                >
                  <mat-icon>table_chart</mat-icon> Admin Tables
                </button>
              </mat-list-item>
            }

            @if (currentPermission.budgetToActualTab.canView) {
              <mat-list-item role="navItem">
                <button
                  mat-button
                  routerLink="/budget-to-actual"
                  class="text-light"
                  style="width: 100%"
                >
                  <mat-icon>data_check</mat-icon> ICP Budget to Actual
                </button>
              </mat-list-item>
            }
          }
          <!-- Not part of initial MVP -->
          <!-- <mat-list-item role="navItem">
                          <button mat-button routerLink="/comp-summary" class="text-light" style="width: 100%">
                              Comp Summary
                          </button>
                      </mat-list-item> -->
        </mat-list>
      </div>
      <div class="pt-4 d-flex flex-column">
        <mat-list role="profile" class="bot-nav w-100">
          <mat-list-item role="profileItem">
            <button mat-button routerLink="/profile" class="text-light w-100">
              <mat-icon>account_circle</mat-icon> Profile
            </button>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </aside>
}
