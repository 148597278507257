import { Component, DoCheck, EventEmitter, OnInit, Output, effect, inject, model } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { UserService } from 'app/shared/services/user/user.service';
import { AccessLevel, Currency } from 'app/core/enums';
import { Region } from 'app/core/views/region.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Permission } from 'app/core/views/current-user.model';
import { filter } from 'rxjs';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit, DoCheck {
  router = inject(Router);
  userService = inject(UserService);

  @Output() logoutUser = new EventEmitter<void>();

  expand = model<boolean>();
  regions: Region[] = [];
  badgeValue = 10;
  selectedCurrency!: string;
  availableYears: any[] = [];
  availableCurrencies: Currency[] = [Currency.USD, Currency.LOCAL];
  selectedYear!: number;
  permission!: Permission;
  selectedRegions: Region[] = [];
  selectedCountries: string[] = [];
  countries: string[] = [];
  isInitialized: boolean = false;
  hasChangedValues: boolean = false;

  showFilterRouteArray: string[] = ['/tdc', '/icp', '/emsp', '/employees', '/progress-payments'];
  showRegionFilter!: boolean;

  constructor() {
    // watching service signal for updates.
    effect(
      () => {
        // Explicitly allow signal writes within the effect
        const user = this.userService.currentUser();
        if (user && user !== null) {
          this.regions = user.regions;
          this.badgeValue = this.userService.notificationCount() ?? 0;
          const countries = this.userService.selectedCountry();
          const regions = this.userService.regionsParam();
          if (countries && regions) {
            const currency = this.userService.selectedCurrency();
            this.selectedCurrency = currency;
            this.permission = user.permission;
            this.availableYears = user.systemConfigurations;
          }
          if (!this.isInitialized) {
            this.selectedRegions = this.regions;
            const distinctCountries = this.userService.getDistinctCountriesInRegion(this.regions);
            this.countries = distinctCountries;
            this.selectedCountries = distinctCountries;
            this.isInitialized = true;
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this.router.events.subscribe({
      next: e => {
        if(e instanceof NavigationEnd){
          const route = this.showFilterRouteArray.find(x => e.url.split('#')[0] === x);
          if(route) {
            const split = route.split('#');
            const url = split[0];
            let frag = ''
            if(split.length > 1){
              frag = split[1];
            }
            this.showRegionFilter = true;
          } else {
            this.showRegionFilter = false;
          }
        }
      }
    });
  }

  ngDoCheck(): void {
    let buttonWidth = document.getElementById('userMenuBtn')?.offsetWidth;
    if (buttonWidth) {
      let elems = document.getElementsByClassName('mat-mdc-menu-panel');
      for (let i = 0; i < elems.length; i++) {
        elems[i].setAttribute('style', `width: ${buttonWidth}px`);
      }
    }
  }

  onCurrencyUpdate(currency: string): void {
    this.userService.updateCurrency(currency);
    this.userService.sendFilterChange('currency');
  }

  changeRegions(event: any): void {
    this.selectedCountries = [];
    const updatedRegions = event.value as Region[];
    this.userService.changeRegions(updatedRegions);
    this.selectedRegions = updatedRegions;
    const distinctCountries = this.userService.getDistinctCountriesInRegion(updatedRegions);
    this.countries = [...distinctCountries];
    this.userService.changeCountries([]);
    this.getRegionSelectedTabText();
    this.getCountrySelectedTabText();
    this.hasChangedValues = true;
  }

  changeCountry(selectedCountries: string[]): void {
    if (selectedCountries) this.userService.changeCountries(selectedCountries);
    this.hasChangedValues = true;
  }

  getRegionSelectedTabText(): string {
    if (this.selectedRegions.length > 0) {
      const selectedRegion = this.selectedRegions[0];
      if (!selectedRegion) {
        return '';
      }
      const region = this.regions.find(
        region => region.regionKey.toLowerCase() === selectedRegion.regionKey.toLowerCase()
      );
      return region?.regionName ?? '';
    }
    return '';
  }

  getCountrySelectedTabText(): string {
    if (this.selectedCountries.length > 0) {
      return this.selectedCountries[0];
    }
    return '';
  }

  trackRegion(index: number, region: any): string {
    return region.regionKey;
  }

  applyLocationFilter(): void {
    this.userService.sendFilterChange('location');
    this.hasChangedValues = false;
  }

  resetLocationFilter(): void {
    // this.changeRegion(this.userService.userRegionKey);
    // this.changeCountry(this.userService.userCountry);
    this.userService.sendFilterChange();
  }

  logout(): void {
    this.userService.currentUser.update(() => null);
    this.logoutUser.emit();
  }

  login(): void {
    this.router.navigate(['/dashboard']);
  }

  refreshYear(event: MatSelectChange): void {
    const year = event.source.value;
    this.userService.updateYear(year);
    this.userService.sendFilterChange('year');
  }
}
