@if(userService.isAPIAuthenticated() && userService.isMsalAuthenticated()){
  <mat-toolbar class="sticky-top" style="box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);">
    <mat-toolbar-row class="pb-1">
      <button mat-icon-button aria-label="Toggle to show / hide side bar." (click)="expand.set(!expand())">
        <mat-icon>menu</mat-icon>
      </button>
      <xng-breadcrumb></xng-breadcrumb>
      <span class="example-spacer"></span>
      @if(badgeValue > 0){
        <button mat-icon-button aria-label="Audit Logs" routerLink="/audit-logs" matTooltip="Click to go to Audit Logs">
          <mat-icon matBadgeColor="accent" aria-hidden="false">notifications</mat-icon>
          <!-- kept for the update to keep the numbers <mat-icon  [matBadge]="badgeValue" matBadgeColor="accent" aria-hidden="false">notifications</mat-icon> -->
        </button>
      }
      <div role="button" [matMenuTriggerFor]="userMenu" id="userMenuBtn" matTooltip="Click to open user options">
        <span>{{userService.currentUser()?.name}}</span>
      </div>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item routerLink="/profile">Profile</button>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </mat-toolbar-row>
    @if(userService.currentAccess() !== 0 && regions.length > 0 && selectedRegions){
    <mat-toolbar-row style="z-index: 0;">
      @if(showRegionFilter){
        <mat-form-field appearance="outline" class="mx-2">
          <mat-label>Region</mat-label>
          <mat-select [(value)]="selectedRegions" multiple (selectionChange)="changeRegions($event)" [disabled]="regions.length === 1">
            @if(selectedRegions.length === 0){
              <mat-select-trigger>
                Select Region
              </mat-select-trigger>
            }
            @if(selectedRegions.length > 0){
              <mat-select-trigger>
                {{getRegionSelectedTabText()}}
                <span *ngIf="selectedRegions.length > 1" class="example-additional-selection">
                  (+{{selectedRegions.length - 1}} {{selectedRegions.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
            }
            <mat-option *ngFor="let region of regions; trackBy: trackRegion" [value]="region">{{region.regionName}}</mat-option>       
          </mat-select>
        </mat-form-field>
        @if(selectedRegions && selectedRegions.length > 0 && countries){
          <mat-form-field appearance="outline" class="mx-2">
            <mat-label>Country</mat-label>
            <mat-select [(value)]="selectedCountries" multiple (valueChange)="changeCountry($event)"
              [disabled]="countries.length === 1 && regions.length === 1">
              @if(selectedCountries && selectedCountries.length > 0){
                <mat-select-trigger>
                  {{getCountrySelectedTabText()}}
                  <span *ngIf="selectedCountries.length > 1" class="example-additional-selection">
                    (+{{selectedCountries.length - 1}} {{selectedCountries.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>
              }
              <mat-option *ngFor="let country of countries;" [value]="country">{{country}}</mat-option>
            </mat-select>
          </mat-form-field>
        }
        <div>
          <div class="d-inline" matTooltip="Enable by selecting a new region or country" [matTooltipDisabled]="hasChangedValues">
            <button class="mx-2" mat-raised-button color="primary" matTooltip="Filter by region and country"
              aria-label="Filters employees based on region and country selected" (click)="applyLocationFilter()"
              [disabled]="!hasChangedValues">
              Apply
            </button>
          </div>
          <!-- <button class="mx-2" mat-stroked-button color="accent" matTooltip="Reset filter to user defaults" aria-labelled="Resets filter to user region/country defaults"
            (click)="resetLocationFilter()">
            Reset <mat-icon>refresh</mat-icon>
          </button> -->
        </div>
      }
      <span class="example-spacer"></span>
      <div class="mx-2 align-self-start">
        @if(permission){
          <mat-button-toggle-group [ngModel]="selectedCurrency" #group="matButtonToggleGroup" (change)="onCurrencyUpdate($event.value)">
            @if(permission.canViewUsdCurrencyButton){
              <mat-button-toggle [checked]="selectedCurrency === 'USD'" value="USD" matTooltip="US Dollars">
                USD
              </mat-button-toggle>
            }
            <mat-button-toggle [checked]="selectedCurrency === 'Local'" value="Local" matTooltip="Currency conversion rates are based on the average prior-month's exchange rates, sourced from Workday Financials currency data." >
              Local
            </mat-button-toggle>
          </mat-button-toggle-group>
        }
      </div>
      <mat-form-field appearance="outline" class="mx-2">
        <mat-label>ICP / TDC Year</mat-label>
        <mat-select [(value)]="userService.selectedYear" (selectionChange)="refreshYear($event)">
          @for (availableYear of availableYears; track availableYear) {
          <mat-option [value]="availableYear.year">
            {{availableYear.icpYear}} / {{availableYear.tdcYear}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </mat-toolbar-row>
    }
  </mat-toolbar>
}