import { Component, effect, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { UserService } from 'app/shared/services/user/user.service';
import { Permission } from 'app/core/views/current-user.model';
import { AccessLevel } from 'app/core/enums';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  userService = inject(UserService);
  currentPermission!: Permission;
  roleName = '';

  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    effect(() => {
      const user = this.userService.currentUser();
      if (user) {
        this.currentPermission = user.permission;
        this.roleName = this.getRoleName(user.role);
      }
    });
  }

  ngOnInit(): void {}

  getRoleName(access: AccessLevel): string {
    switch (access) {
      case 100:
        return 'Global';
      case 90:
        return 'Compensation Committee';
      case 65:
        return 'RMD';
      case 55:
        return 'SrMD';
      case 45:
        return 'MD';
      case 35:
        return 'Solution MD';
      case 25:
        return 'Industry MD';
      case 15:
        return 'Country Market Leader';
      case 5:
        return 'Global Industry Leader';
      case 0:
        return 'None';
      default:
        return '';
    }
  }
}
